import React from "react";
import { makeCardGridItems, LeadDetails } from "@leadId/index";
import { createLeadView, ViewComponent } from "@leadId/view";
import {
    CreditScoreLarge,
    CreditInquiries,
    TradeLines,
    HomeInfo,
    EmploymentInfo,
} from "@leadId/data/attributes";
import { useLeadView } from "@api/leads";
import { LeadContent } from "./_lib";

export const AvaCreditCards = makeCardGridItems<any, LeadDetails>([
    CreditScoreLarge,
    CreditInquiries,
    TradeLines,
    EmploymentInfo,
    HomeInfo,
]);

const AvaCredit: ViewComponent = (props) => {
    const { lead, loading, error } = useLeadView(props.leadId, "");

    return (
        <LeadContent
            cards={AvaCreditCards}
            lead={lead}
            loading={loading}
        />
    );
};

export default createLeadView(AvaCredit, {});